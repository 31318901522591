import { camelCase } from 'lodash'

const requireModule = require.context('.', false, /\.js$/) // Extract js files in modules folder

const modules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''))

  modules[moduleName] = requireModule(fileName).default
})

export default modules

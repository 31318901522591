import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  // needed if operating on sub path
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () =>
        import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
      meta: { requiresAuthentication: false },
      children: [
        {
          path: '/start',
          name: 'Start',
          component: () =>
            import(/* webpackChunkName: "Start" */ '../views/Start.vue'),
          meta: { requiresAuthentication: false }
        },
        {
          path: '/about',
          name: 'About',
          component: () =>
            import(/* webpackChunkName: "About" */ '../views/About.vue'),
          meta: { requiresAuthentication: false }
        },
        {
          path: '/details',
          name: 'Details',
          component: () =>
            import(/* webpackChunkName: "Details" */ '../views/Details.vue'),
          meta: { requiresAuthentication: false }
        },
        {
          path: '/info',
          name: 'Info',
          component: () =>
            import(/* webpackChunkName: "Info" */ '../views/Info.vue'),
          meta: { requiresAuthentication: false }
        },
        {
          path: '/delivery',
          name: 'Delivery',
          component: () =>
            import(/* webpackChunkName: "Delivery" */ '../views/Delivery.vue'),
          meta: { requiresAuthentication: false }
        },
        {
          path: '/summary',
          name: 'Summary',
          component: () =>
            import(/* webpackChunkName: "Summary" */ '../views/Summary.vue'),
          meta: { requiresAuthentication: false }
        },
        {
          path: '/confirmation',
          name: 'Confirmation',
          component: () =>
            import(
              /* webpackChunkName: "Confirmation" */ '../views/Confirmation.vue'
            ),
          meta: { requiresAuthentication: false }
        }
      ]
    },
    {
      path: '/404',
      alias: '*',
      name: 'NotFound',
      component: () =>
        import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue')
    }
  ]
})

// const router = new VueRouter({
//   scrollBehavior () {
//     return { x: 0, y: 0 }
//   },
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router

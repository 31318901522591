<template>
  <v-app id="app">
    <Header />
    <v-snackbar
      v-for="(snackbar, index) in snackbars.filter((s) => s.visible)"
      :key="snackbar.text + Math.random()"
      :value="snackbar.visible"
      :bottom="true"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      :style="`bottom: ${index * 60 + 8}px`"
      @input="closeSnackbar(snackbar)"
    >
      {{ snackbar.text }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeSnackbar(snackbar)">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <router-view />
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Header from '@/components/Header.vue'
export default {
  components: { Header },
  data: function () {
    return {
      copyright: 'Foto-Kem Industries, Inc. All rights reserved.'
    }
  },
  watch: {},
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({ project: 'project/project' })
  },
  created () {
    // this.$vuetify.theme.dark = this.isDarkMode
  },
  methods: {
    closeSnackbar (snackbar) {
      this.$store.dispatch('snackbar/close', snackbar)
    }
  }
}
</script>

<style lang="scss">
#app {
  color: #707070;
  padding-bottom: 0;
}

.page {
  position: relative;
  overflow: auto;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

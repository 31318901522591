import { remove } from 'lodash'

export default {
  namespaced: true,

  state: () => ({
    snackbars: []
  }),
  mutations: {
    SET_SNACKBAR (state, snackbar) {
      state.snackbars = state.snackbars.concat(snackbar)
    },
    CLOSE (state, snackbar) {
      snackbar.visible = false
      remove(state.snackbars, snackbar)
    }
  },
  actions: {
    setSnackbar ({ commit }, snackbar) {
      snackbar.visible = true
      snackbar.color = snackbar.color || 'success'
      commit('SET_SNACKBAR', snackbar)
    },
    close ({ commit }, snackbar) {
      commit('CLOSE', snackbar)
    }
  }
}

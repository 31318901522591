export default {
  namespaced: true,

  state: () => ({
    isLoading: false,
    isLoadingProject: false,
    order: {
      uuid: null,
      orderType: null,
      orderFor: 'Non-student Project',
      projectTitle: null,
      projectType: null,
      shootLocation: null,
      shootDates: [],
      dropDate: null,
      film: [],
      scanning: {},
      client: {
        fullName: null,
        title: null,
        organization: null,
        existingAccount: 'Unsure',
        accountCode: null,
        phone: null,
        email: null,
        contactMethod: [],
        contactCall: false,
        contactText: false,
        contactEmail: false
      },
      delivery: {
        type: 'Unsure',
        format: null,
        formatOther: null,
        backup: 'No',
        backupCount: null,
        digitalMethod: 'globalDATA',
        digitalMethodDetails: null,
        additionalNotes: null
      },
      additionalProjectNotes: null
    }
  }),
  mutations: {
    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },
    SET_IS_LOADING_PROJECT (state, isLoading) {
      state.isLoadingProject = isLoading
    },
    SET_ORDER (state, order) {
      state.order = order
    },
    SET_ORDER_UUID (state, uuid) {
      state.order.uuid = uuid
    },
    SET_PROJECT_TITLE (state, projectTitle) {
      state.order.projectTitle = projectTitle
    },
    SET_ORDER_TYPE (state, orderType) {
      state.order.orderType = orderType
    },
    SET_ORDER_FOR (state, orderFor) {
      state.order.orderFor = orderFor
    },
    SET_PROJECT_TYPE (state, projectType) {
      state.order.projectType = projectType
    },
    SET_SHOOT_LOCATION (state, shootLocation) {
      state.order.shootLocation = shootLocation
    },
    SET_SHOOT_DATES (state, shootDates) {
      state.order.shootDates = shootDates
    },
    SET_DROP_DATE (state, dropDate) {
      state.order.dropDate = dropDate
    },

    SET_FILM (state, film) {
      state.order.film = film
    },
    ADD_FILM (state, { film }) {
      state.order.film.push({ ...film })
    },
    REMOVE_FILM (state, { uuid }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        state.order.film.splice(index, 1)
      }
    },
    UPDATE_FILM (state, { uuid, film }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        state.order.film.splice(index, 1, film)
      }
    },

    SET_FILM_PROCESSING (state, { uuid, processing }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        state.order.film[index].processing = { ...processing }
      }
    },
    SET_FILM_SCANNING (state, { uuid, scanning }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        state.order.film[index].scanning = { ...scanning }
      }
    },
    ADD_FILM_SCANNING (state, { uuid, scanning }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        state.order.film[index].scanning.push({ ...scanning })
      }
    },
    REMOVE_FILM_SCANNING (state, { uuid }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        state.order.film[index].scanning = {
          isNeeded: false,
          resolution: '2K',
          gateAperture: 'Full aperture',
          frameRate: '24fps',
          additionalScanningInstructions: null,
          editorialMedia: 'No',
          editorialMediaResolution: null,
          editorialMediaCodec: null,
          editorialMediaColor: null
        }
      }
    },

    SET_PROCESSING (state, processing) {
      state.order.processing = processing
    },

    SET_SCANNING (state, scanning) {
      state.order.scanning = scanning
    },

    SET_CLIENT_NAME (state, fullName) {
      state.order.client.fullName = fullName
    },
    SET_CLIENT_TITLE (state, title) {
      state.order.client.title = title
    },
    SET_CLIENT_ORGANIZATION (state, organization) {
      state.order.client.organization = organization
    },
    SET_CLIENT_EXISTING_ACCOUNT (state, existingAccount) {
      state.order.client.existingAccount = existingAccount
    },
    SET_CLIENT_ACCOUNT_CODE (state, accountCode) {
      state.order.client.accountCode = accountCode
    },
    SET_CLIENT_PHONE (state, phone) {
      state.order.client.phone = phone
    },
    SET_CLIENT_EMAIL (state, email) {
      state.order.client.email = email
    },
    SET_CLIENT_CONTACT_METHOD (state, contactMethod) {
      state.order.client.contactMethod = contactMethod
    },

    SET_DELIVERY_TYPE (state, type) {
      state.order.delivery.type = type
    },
    SET_DELIVERY_SHIPPING_ADDRESS (state, shippingAddress) {
      state.order.delivery.shippingAddress = shippingAddress
    },
    SET_DELIVERY_SHIPPING_METHOD (state, shippingMethod) {
      state.order.delivery.shippingMethod = shippingMethod
    },
    SET_DELIVERY_SHIPPING_ACCOUNT (state, shippingAccount) {
      state.order.delivery.shippingAccount = shippingAccount
    }
  },
  actions: {
    setOrder ({ commit }, { order }) {
      commit('SET_ORDER', order)
    },
    setUuid ({ commit }, { uuid }) {
      commit('SET_ORDER_UUID', uuid)
    },
    setProjectTitle ({ commit }, { projectTitle }) {
      commit('SET_PROJECT_TITLE', projectTitle)
    },
    setProjectType ({ commit }, { projectType }) {
      commit('SET_PROJECT_TYPE', projectType)
    },
    setOrderType ({ commit }, { orderType }) {
      commit('SET_ORDER_TYPE', orderType)
    },
    setOrderFor ({ commit }, { orderFor }) {
      commit('SET_ORDER_FOR', orderFor)
    },
    setShootLocation ({ commit }, { shootLocation }) {
      commit('SET_SHOOT_LOCATION', shootLocation)
    },
    setShootDates ({ commit }, { shootDates }) {
      commit('SET_SHOOT_DATES', shootDates)
    },
    setDropDate ({ commit }, { dropDate }) {
      commit('SET_DROP_DATE', dropDate)
    },
    addFilm ({ commit }, { film }) {
      commit('ADD_FILM', { film })
    },
    removeFilm ({ state, commit }, { uuid }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        commit('REMOVE_FILM', { uuid })
      }
    },
    updateFilm ({ state, commit }, { uuid, film }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        commit('UPDATE_FILM', { uuid, film })
      }
    },
    removeFilmScanning ({ state, commit }, { uuid }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        commit('REMOVE_FILM_SCANNING', { uuid })
      }
    },
    setFilmProcessing ({ state, commit }, { uuid, processing }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        commit('SET_FILM_PROCESSING', { uuid, processing })
      }
    },
    setFilmScanning ({ state, commit }, { uuid, scanning }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1) {
        commit('SET_FILM_SCANNING', { uuid, scanning })
      }
    },
    addFilmScanning ({ state, commit }, { uuid, scanningUuid }) {
      const index = state.order.film.findIndex(
        (element) => element.uuid === uuid
      )

      if (index !== -1 && scanningUuid) {
        commit('ADD_FILM_SCANNING', { uuid, scanningUuid })
      }
    },
    setProcessing ({ commit }, { processing }) {
      commit('SET_PROCESSING', processing)
    },
    setScanning ({ commit }, { scanning }) {
      commit('SET_SCANNING', scanning)
    },

    setClientName ({ commit }, { fullName }) {
      commit('SET_CLIENT_NAME', fullName)
    },
    setClientTitle ({ commit }, { title }) {
      commit('SET_CLIENT_TITLE', title)
    },
    setClientOrganization ({ commit }, { organization }) {
      commit('SET_CLIENT_ORGANIZATION', organization)
    },
    setClientExistingAccount ({ commit }, { existingAccount }) {
      commit('SET_CLIENT_EXISTING_ACCOUNT', existingAccount)
    },
    setClientAccountCode ({ commit }, { accountCode }) {
      commit('SET_CLIENT_ACCOUNT_CODE', accountCode)
    },
    setClientPhone ({ commit }, { phone }) {
      commit('SET_CLIENT_PHONE', phone)
    },
    setClientEmail ({ commit }, { email }) {
      commit('SET_CLIENT_EMAIL', email)
    },
    setClientContactMethod ({ commit }, { contactMethod }) {
      commit('SET_CLIENT_CONTACT_METHOD', contactMethod)
    },

    setDeliveryType ({ commit }, { type }) {
      commit('SET_DELIVERY_TYPE', type)
    },
    setDeliveryShippingAddrss ({ commit }, { shippingAddrss }) {
      commit('SET_DELIVERY_SHIPPING_ADDRESS', shippingAddrss)
    },
    setDeliveryShippingMethod ({ commit }, { shippingMethod }) {
      commit('SET_DELIVERY_SHIPPING_METHOD', shippingMethod)
    },
    setDeliveryShippingAccount ({ commit }, { shippingAccount }) {
      commit('SET_DELIVERY_SHIPPING_ACCOUNT', shippingAccount)
    }
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isLoadingProject: (state) => state.isLoadingProject,

    getOrder: (state) => state.order,

    getUuid: (state) => (state.order ? state.order.uuid : null),
    getProjectTitle: (state) => (state.order ? state.order.projectTitle : null),
    getOrderType: (state) => (state.order ? state.order.orderType : null),
    getOrderFor: (state) => (state.order ? state.order.orderFor : null),
    getProjectType: (state) => (state.order ? state.order.projectType : null),
    getShootLocation: (state) =>
      state.order ? state.order.shootLocation : null,
    getShootDates: (state) => (state.order ? state.order.shootDates : null),
    getDropDate: (state) => (state.order ? state.order.dropDate : null),
    getFilm: (state) => (state.order ? state.order.film : []),
    getProcessing: (state) => (state.order ? state.order.processing : null),
    getScanning: (state) => (state.order ? state.order.scanning : null),

    getClientFullName: (state) =>
      state.order ? state.order.client.fullName : null,
    getClientTitle: (state) => (state.order ? state.order.client.title : null),
    getClientOrganization: (state) =>
      state.order ? state.order.client.organization : null,
    getClientExistingAccount: (state) =>
      state.order ? state.order.client.existingAccount : null,
    getClientAccountCode: (state) =>
      state.order ? state.order.client.accountCode : null,
    getClientPhone: (state) => (state.order ? state.order.client.phone : null),
    getClientEmail: (state) => (state.order ? state.order.client.email : null),
    getClientContactMethod: (state) =>
      state.order ? state.order.client.contactMethod : null,

    getDeliveryShippingAddress: (state) =>
      state.order ? state.order.delivery.shippingAddress : null,
    getDeliveryType: (state) =>
      state.order ? state.order.delivery.type : null,
    getDeliveryShippingMethod: (state) =>
      state.order ? state.order.delivery.shippingMethod : null,
    getDeliveryShippingAccount: (state) =>
      state.order ? state.order.delivery.shippingAccount : null
  }
}

<template>
  <header
    class="nl-app-header d-flex justify-center align-center"
    :color="$vuetify.theme.dark ? 'black' : 'white'"
    app
    fixed
    tile>
    <img v-if="$vuetify.theme.dark"
      alt="FotoKem logo"
      src="@/assets/fotokem-main-digital-web2.png"
    />
    <img v-else
      alt="FotoKem logo"
      src="@/assets/fotokem-main-digital-web2-reverse.png"
    />
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    copyright: String
  },
  computed: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.nl-app-header {
  position: fixed;
  top:0;
  left:0;
  width:100%;
  background-color: transparent;
  padding: 12px 15px 12px;
  z-index: 1001;
}
.nl-app-header--btn {
  margin-left: 0 !important;
  margin-right: 8px;
}
</style>
